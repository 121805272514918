<template>
  <div>
    <div :style="dynamicBgImg">
      <div class="back-black" :class="{ 'normal-back': websiteStore.getIsCampaign && destinationDetails.isHome }">
        <div class="container pt-5 pb-5 pb-0">
          <div class="row  pt-5">
            <div class="col d-flex justify-content-center text-center title-banner">
              <h2 :style="getTitleStyle">
                <b>{{ destinationDetails.name }}</b>
              </h2>
            </div>
          </div>
          <div class="row gx-2 pb-lg-5 justify-content-center">
            <div class="col-lg-4 col-9">
              <select class="form-select form-control input" v-model="selected">
                <option v-for="destination in destinations.slice(0, 6)" :key="destination.id" :value="destination.id"
                  :hidden="destination.hidden">
                  {{ destination.name }}
                </option>
              </select>
            </div>
            <!-- <div class="col-lg-3 col-8">
                    <select
                      class="form-select input"
                      aria-label="Default select example"
                    >
                      <option value="1">Winter</option>
                      <option value="2">Spring</option>
                      <option value="3">Summer</option>
                      <option value="3">Fall</option>
                    </select>
                  </div> -->
            <div class="col-auto">
              <router-link :to="toRoute" custom v-slot="{ navigate }">
                <button type="submit" class="btn submit material-icons-outlined" style="color: white" @click="navigate">
                  arrow_forward
                </button>
              </router-link>
            </div>
          </div>
          <!--MOBILE-->
          <div class="row d-lg-none" v-if="websiteStore.getIsCampaign">
            <div class="col text-center">
              <!-- <img src="../assets/images/campaigns/fathers-day/icon-mobile.png" alt="" class="w-50"> -->
            </div>
          </div>
          <!---->
        </div>
      </div>
      <TheRandomIcon :type="1"/>
    </div>
    <a href="tel:+18776923883" class="text-decoration-none">
      <div class="row  justify-content-center p-4 campaign-lbl-section" :style="websiteStore.campaign.bgCampaign" v-if="websiteStore.getIsCampaign">
        <!--MOBILE-->
        <!-- <div class="text-center pb-2"><img :src="websiteStore.campaign.babyAsset" alt="" class="w-25 d-lg-none"></div>
        <span class="campaign-font  d-lg-none text-center">{{ websiteStore.campaign.lbl01 }}</span> -->
        <div class="col-8 text-center d-lg-none d-grid brandig-mobile">
          <img src="../assets/images/campaigns/independence-day-2024/sale-lbl.png" alt="" class="w-50 pb-4">
          <span class="campaign-font  d-lg-none text-center">{{ websiteStore.campaign.lbl01 }}</span>
          <div class="text-center pb-2"><img :src="websiteStore.campaign.babyAsset" alt="" class="w-50 d-lg-none"></div>
          <p class="mb-0"><span class="campaign-font fs-3"> {{ websiteStore.campaign.lbl02 }}</span> <br> <span class="campaign-font-02 fs-2">{{ websiteStore.campaign.lbl03 }}</span>
        </p>
        </div>
        <div class="col-12 text-center d-lg-none">
  
        </div>
        <div class="col text-center d-lg-none">
         
        </div>
        <!--DESKTOP-->
        <div class="col text-end  align-content-center d-none d-lg-grid pe-1">
          <span class="campaign-font">{{ websiteStore.campaign.lbl01 }}</span>
        </div>
        <div class="col d-none d-lg-flex align-items-center ps-0">
          <img :src="websiteStore.campaign.babyAsset" alt="" class="baby-asset">
          <p class="mb-0"><span class="campaign-font fs-4">  {{ websiteStore.campaign.lbl02 }}</span> <span class="campaign-font-02 fs-4">{{ websiteStore.campaign.lbl03 }}</span>
          </p>
          <!-- <span class="campaign-font-02">CALL NOW</span>  -->
        </div>
      </div>
    </a>

    <!-- <div class="row bg-campaign p-4" v-if="websiteStore.getIsCampaign">
      <div class="col col-lg-6 text-lg-end text-center align-content-center d-grid ">
        <span class="campaign-font ">This St. Patrick's, Every Vacation Package Holds A pot Of Gold.</span>
        </div>
        <div class="col-lg-6 text-lg-start text-center d-inline">
          <img src="../assets/images/campaigns/patricks/coin.png" alt=""><span class="campaign-font-02">CALL NOW</span> <span class="campaign-font">For Your Fortune And Unlock A Leprechaun's Secret Extra Bonus For Free!</span> <span class="campaign-font-02">877 692 3883</span>
      </div>
    </div> -->
  </div>
</template>

<script>
const getImagePath = (image = "") => {
  return require(`@/assets/images/filter_banner_imgs/${image}`)
}

import { useWebsiteStore } from '@/store/website';

export default {
  setup() {
    const websiteStore = useWebsiteStore();
    return {
      websiteStore
    }
  },
  props: {
    destinationDetails: {
      type: Object,
      default: function () {
        return {
          image: getImagePath("cover.webp"),
          name: "Look for the Best Vacation Deal",
          isHome: true
        }
      }
    },
    slug: String
  },
  watch: {
    slug(value) {
      this.checkSlug(value);
    }
  },
  data() {
    return {
      selected: 0,
      dynamicBgImg: '',
      titleStyle: ''
    };
  },
  computed: {
    dynamicBg() {
      return this.dynamicBgImg
    },
    destinations() {
      let destinations = [...this.websiteStore.destinations];

      destinations.forEach(x => {
        x.componentName = 'destination';
      });

      destinations.unshift({
        id: 0,
        name: '',
        componentName: 'home',
        hidden: true,
      });

      return destinations;
    },
    toRoute() {
      let destination = this.destinations.find(x => x.id == this.selected);

      let toRoute = { name: destination.componentName };

      if (destination.slug) {
        toRoute.params = {
          slug: destination.slug
        };
      }

      return toRoute;
    },
    getTitleStyle() {
      this.setTitleColor()
      return this.titleStyle
    }
  },
  methods: {
    checkSlug(slug) {
      if (slug) {
        this.selected = this.destinations.find(x => x.slug == slug).id;
      } else {
        this.selected = 0;
      }
    },
    setTitleColor() {
      if(this.websiteStore.getIsCampaign) {
        this.titleStyle=this.websiteStore.campaign.titleColor
      }
    }
  },
  mounted() {
    this.checkSlug(this.slug);
    if (this.websiteStore.getIsCampaign) {
      this.dynamicBgImg = this.dynamicBgImg=`background-image: url(${this.websiteStore.campaign.bgBanner.backgrImage});background-size: cover;background-position: center !important;`
    } else {
      this.dynamicBgImg=`background-image: url(${this.destinationDetails.image});background-size: cover;background-position: center !important;`;
    }
  }
};
</script>

<style scoped>
.submit {
  background: var(---0c9491) 0% 0% no-repeat padding-box;
  background: #0c9491 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 48px;
  width: 100%;
}

.submit:hover {
  background-color: #0A6B69;
}

.back-black {
  background-color: rgba(0, 0, 0, 0.5);
}

.normal-back {
  background-color: transparent;
}

.title-banner {
  color: #ffff;
}

.card-title {
  color: #0c9491;
  font-weight: bold;
  font-size: 24px;
}

.card-text {
  font-size: 16px;
}

.btn-package {
  background: transparent linear-gradient(90deg, var(---0c9491) 0%, var(---3a2050) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #0c9491 0%, #3a2050 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffff;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  font-size: 12px;
}

.card-img-overlay {
  color: #ffff;
}

.place {
  font-size: 14px;
}

.retail-price {
  display: block;
  text-align: left;
  text-decoration: line-through;
  font: normal normal normal 12px/24px Poppins;
}

.price {
  text-align: left;
  font: normal normal bold 24px/24px Poppins;
}

.per {
  text-align: left;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
}

.card-body {
  background-color: #fff;
}

.span {
  display: block;
}

.material-icons-outlined {
  line-height: none !important;
}

.input {
  border: 2px solid #0c9491;
  height: 48px;
}

h2 {
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: bold;
  color: #ffff;
}

.campaign-font {
  font-family: Poppins !important;
  ;
  font-weight: 900;
  color: #fff;
}

.campaign-font-02 {
  font-family: Poppins !important;
  ;
  font-weight: 900;
  color: #fff;
}

.title-campaign {
  color: #ffffff;
}

.baby-asset {
  width: 7%;
}
.brandig-mobile {
  justify-items: center;
}
@media (min-width: 992px) { 
  .campaign-lbl-section {
border-top: #025d20 2px solid;
}
 }

</style>